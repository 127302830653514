<template>
  <div class="main-content">
    <nav class="breadcrumb">
      <ul class="breadcrumb__nav">
        <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><router-link :to="{ name: 'AdminsList' }"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></router-link></li>
        <li class="breadcrumb__nav-item"><router-link :to="{ name: 'AdminsList' }">{{$t('admininvite.breadcrumb')}}</router-link></li>
        <li class="breadcrumb__nav-item">{{$t('admininvite.breadcrumbnavitem')}}</li>
      </ul>
    </nav>
    <header class="titles-header">
      <div class="titles-header__side">
        <h2 class="titles-header__title">{{$t('admininvite.titleedit')}}</h2>
        <p class="titles-header__subtitle subtitle"></p>
      </div>
    </header>
    <section class="main-section">
      <form @submit.prevent="inviteUser()">
        <div class="form__group">
          <label class="form__label" for="email">{{$t('admininvite.labelemail')}}</label>
          <input class="form__input" type="text" v-model.trim="email" id="email" autofocus required>
          <!-- <span class="form__input-info"></span> -->
        </div>

        <!-- Solucion momentanea más triste del mundo :( -->
        <br><br><br><br>

        <div class="form__actions form__actions--fixed">
          <div class="form__actions-side">
          </div>
          <div class="form__actions-side">
            <button class="btn btn--primary" type="submit">{{$t('admininvite.btninvite')}}</button>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AdminsInvite',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    inviteUser () {
      this.$axios.post('invites/admin', { email: this.email }).then(() => {
        alert(this.$t('admininvite.successmsg'))
        this.$router.push({ name: 'AdminsList' })
      })
    }
  }
}
</script>
